import React from "react";
import {
  Chart,
  ChartTitle,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip
} from "@progress/kendo-react-charts";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { getListings, getRentalHistory, getPastRentalListings } from "../services/dataService";
import { Listings, RentalHistory } from "../data/models";
import { Listing } from "../data/models";

export default function ListingsPanel(props: { selectedZip: string; }) {
  const [data, setListings] = React.useState<Listing[]>();
  const [rentalhistorydata, setRentalHistory] = React.useState<RentalHistory[]>();
  const [pastrentaldata, setPastRentalData] = React.useState<Listing[]>();  
  const [title, setTitle] = React.useState("Sqft vs Rent (" + props.selectedZip + ")");
  const [rentalhistorytitle, setRentalHistoryTitle] = React.useState(" Rental History (" + props.selectedZip + ")");
  const [pastrentaltitle, setPastRentalTitle] = React.useState("Sqft vs Rent (" + props.selectedZip + ")");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [activeRentalsLoaded, setActiveRentalsLoaded] = React.useState(false);  
  const [rentalHistoryLoaded, setRentalHistoryLoaded] = React.useState(false);
  const [pastRentalsLoaded, setPastRentalsLoaded] = React.useState(false);

  React.useEffect(() => {
    console.log("RentalListings panel rendered - " + props.selectedZip);    
    setRentalHistoryLoaded(false);
    setActiveRentalsLoaded(false);
    setPastRentalsLoaded(false);
    setListings([]);    
    setPastRentalData([]);
    setRentalHistory([]);
    console.log("Selected Tab: " + selectedTab);
    if (selectedTab === 0) {
      loadActiveRentals();
    } else if (selectedTab === 1) {
      loadRentalHistory();
    } else if (selectedTab === 2) {
      loadPastRentals();
    }
  }, [props.selectedZip]);

  function loadActiveRentals() {
    getListings(props.selectedZip).then((results: Listings) => {
      setListings(results.marketListings);
      if (results.totalListings === 0) {
        setTitle("No Results");
      } else {
        setTitle(results.city + " (" + results.zip + ") | ActiveListings:" + results.totalListings);
      }
      setActiveRentalsLoaded(true);
    })
  }

  function loadRentalHistory() {
    console.log("Is rentalHistoryLoaded: " + rentalHistoryLoaded);
      getRentalHistory(props.selectedZip).then((results: RentalHistory[]) => {
        setRentalHistory(results);
        if (results.length > 0) {
          var total = 0;
          results.forEach( (result) => {
            total = total + result.totalListings;
          })
          setRentalHistoryTitle(results[0].city + " (" + results[0].zip + ") | Total Rentals:" + total);      
        }
        setRentalHistoryLoaded(true);        
      });
  }

  function loadPastRentals() {
    getPastRentalListings(props.selectedZip).then((results: Listings) => {
      setPastRentalData(results.marketListings);
      setPastRentalTitle(results.city + " (" + results.zip + ") | RentedListings:" + results.totalListings);
      setPastRentalsLoaded(true);
    })
  }

  const handleSelect = (e: any) => {
    setSelectedTab(e.selected);
    if (e.selected === 1 && rentalHistoryLoaded === false) {
      loadRentalHistory();
    } else if (e.selected === 0 && activeRentalsLoaded === false) {
      loadActiveRentals();
    } else if (e.selected === 2 && pastRentalsLoaded === false) {
      loadPastRentals();
    }
  }

  return (
  <TabStrip selected={selectedTab} onSelect={handleSelect}>
    <TabStripTab title="Active Rentals" contentClassName="panel-positions">
      <Chart style={{ width: 1100 }}>
        <ChartTitle text={title} color="blue" border={{color: "green", width: 2}}/>
        <ChartTooltip render={(e: any) => (
            <div>
              <a href={e.point.dataItem.listingId} target="_blank" >{e.point.dataItem.address}</a>
              <br></br> 
              {e.point.dataItem.sqft} Sqft - ${e.point.dataItem.listPrice}
              <br></br> 
              {e.point.dataItem.daysOnMarket} DOM
            </div>
        )} />
        <ChartSeries>
            <ChartSeriesItem
                type="scatter"
                data={data}
                xField="sqft"
                yField="listPrice"
            />
        </ChartSeries>
        <ChartXAxis>
            <ChartXAxisItem majorUnit={100} title={{ text: 'Area (Sqft)' }} min={1000} max={2800} type="numeric"/>
        </ChartXAxis>
        <ChartYAxis>
            <ChartYAxisItem title={{ text: 'Rent ($)' }} min={1000} max={3000} type="numeric"/>
        </ChartYAxis>
    </Chart>
    </TabStripTab>

    <TabStripTab title="3 Month Rental History">
      <Chart style={{ width: 1100 }}>
      <ChartTitle text={rentalhistorytitle} color="blue" border={{color: "green", width: 2}}/>
      <ChartSeries>
        <ChartSeriesItem
          type="bubble"
          xField="sqft"
          yField="listPrice"
          sizeField="totalListings"
          categoryField="city"
          data={rentalhistorydata}
        />
      </ChartSeries>
      <ChartXAxis>
        <ChartXAxisItem
          majorUnit={100}
          min={1000}
          labels={{ format: "{0:N0}", skip: 1, rotation: 'auto' }} />
      </ChartXAxis>
      <ChartYAxis>
        <ChartYAxisItem labels={{ format: '{0:N0}' }}/>
      </ChartYAxis>
      <ChartTooltip format="{0} Sqft: ${1}: {2:N0} Rentals" opacity={1} />
    </Chart>
    </TabStripTab>

    <TabStripTab title="Past Rentals" contentClassName="panel-positions">
      <Chart style={{ width: 1100 }}>
        <ChartTitle text={pastrentaltitle} color="blue" border={{color: "green", width: 2}}/>
        <ChartTooltip render={(e: any) => (
            <div>
              <a href={e.point.dataItem.listingId} target="_blank" >{e.point.dataItem.address}</a>
              <br></br> 
              {e.point.dataItem.sqft} Sqft - ${e.point.dataItem.listPrice}
            </div>
        )} />
        <ChartSeries>
            <ChartSeriesItem
                type="scatter"
                data={pastrentaldata}
                xField="sqft"
                yField="listPrice"
            />
        </ChartSeries>
        <ChartXAxis>
            <ChartXAxisItem majorUnit={100} title={{ text: 'Area (Sqft)' }} min={1000} max={2800} type="numeric"/>
        </ChartXAxis>
        <ChartYAxis>
            <ChartYAxisItem title={{ text: 'Rent ($)' }} min={1000} max={3000} type="numeric"/>
        </ChartYAxis>
    </Chart>
    </TabStripTab>

  </TabStrip>
  )
}
import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Chart,
  ChartTitle,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip
} from "@progress/kendo-react-charts";
import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { getLandListings, getPastLandListings, getROIEstimate, getCashFlow } from "../services/dataService";
import { Listings} from "../data/models";
import { Listing } from "../data/models";

export default function ListingsPanel(props: { selectedZip: string; }) {
  const [activedata, setSalesListings] = React.useState<Listing[]>([]);
  const [latestdata, setLatestListings] = React.useState<Listing[]>([]);  
  const [pendingdata, setPendingData] = React.useState<Listing[]>([]);
  const [pastsalesdata, setPastSalesData] = React.useState<Listing[]>([]);
  const [selectedTab, setSelectedTab] = React.useState(0);  
  const [title, setTitle] = React.useState("Acres vs ListPrice (" + props.selectedZip + ")");
  const [pastsalestitle, setPastSalesTitle] = React.useState("Acres vs ListPrice (" + props.selectedZip + ")");
  const [activeChecked, setActiveChecked] = React.useState(true);
  const [pendingChecked, setPendingChecked] = React.useState(true);
  const [activeSalesLoaded, setActiveSalesLoaded] = React.useState(false);  
  const [pastSalesLoaded, setPastSalesLoaded] = React.useState(false);  
  const [minListingPrice, setMinListingPrice] = React.useState<number>(0);
  const [maxListingPrice, setMaxListingPrice] = React.useState<number>(1000000);
  const [minAcres, setMinAcres] = React.useState<number>(0);
  const [maxAcres, setMaxAcres] = React.useState<number>(20);
  const [acresInterval, setAcresInterval] = React.useState<number>(1);
  const [priceInterval, setPriceInterval] = React.useState<number>(100000);

  React.useEffect(() => {
    console.log("LandListings panel rendered - " + props.selectedZip);
    setSelectedTab(0);
    setSalesListings([]);
    setLatestListings([]);
    setPendingData([]);
    setPastSalesData([]);
    setPastSalesLoaded(false);
    setActiveSalesLoaded(false);

    if (selectedTab === 0) {
      loadActiveSales();
    } else if (selectedTab === 1) {
      loadPastSales();
    }

  }, [props.selectedZip]);

  function loadActiveSales() {
    getLandListings(props.selectedZip).then((results: Listings) => {
      if (props.selectedZip === "70000") {
        setTitle(" Recent Listings | Total:" + results.totalListings);        
      } else {
        setTitle(results.city + " (" + results.zip + ") | TotalListings:" + results.totalListings);
      }
      setSalesListings(results.activeListings);
      setPendingData(results.underContractListings);
      setLatestListings(results.latestListings);
      console.log("Loaded SalesListings");
    });
    setActiveSalesLoaded(true);
  }

  function loadPastSales() {
    getPastLandListings(props.selectedZip).then((results: Listings) => {
      setPastSalesData(results.marketListings);
      if (results.totalListings === 0) {
        setPastSalesTitle("No Results");
      } else {
        setPastSalesTitle(results.city + " (" + results.zip + ") | SoldListings:" + results.totalListings);
      }
    });
    setPastSalesLoaded(true);
  }

  const handleSelect = (e: any) => {
    setSelectedTab(e.selected);
    if (e.selected === 0 && activeSalesLoaded === false) {
      loadActiveSales();
    } else if (e.selected === 1 && pastSalesLoaded === false) {
      loadPastSales();
    }
  }  

  const changeMaxPrice = (e: any) => {
    setMaxListingPrice(e.value);
    if(e.value > 1000000) {
      setPriceInterval(100000);
    } else {
      setPriceInterval(50000);
    }
  }

  const changeMaxAcres = (e: any) => {
    setMaxAcres(e.value);
    if(e.value > 100) {
      setAcresInterval(2);
    } else {
      setAcresInterval(1);
    }
  }

  return (
  <TabStrip selected={selectedTab} onSelect={handleSelect}>
    <TabStripTab title="Active Sales" contentClassName="panel-positions">
      <div>
        <span>
      <Checkbox defaultChecked={activeChecked} label={'Active'} onChange= {e=>setActiveChecked(!activeChecked)}/>
      &nbsp;&nbsp;|&nbsp;&nbsp; <Checkbox defaultChecked={pendingChecked} label={'Contract'} onChange= {e=>setPendingChecked(!pendingChecked)}/>
      &nbsp;&nbsp;|&nbsp;&nbsp; MaxPrice: <NumericTextBox value={maxListingPrice} width="150px" step={100000} onChange={changeMaxPrice}/> 
      &nbsp;&nbsp;|&nbsp;&nbsp; MaxAcres: <NumericTextBox value={maxAcres} width="150px" step={1} onChange={changeMaxAcres}/> 
      </span>
      <Chart style={{ width: 1100 }}>
          <ChartTitle text={title} color="black" background="cyan" border={{color: "green", width: 2}}/>
          <ChartTooltip render={(e: any) => (
              <div>
                <a href={e.point.dataItem.listingId} target="_blank" >{e.point.dataItem.address}</a>
                <br></br> 
                {e.point.dataItem.acres} Acres - ${e.point.dataItem.listPrice}
                <br></br> 
                {e.point.dataItem.listingStatus}
                &nbsp;&nbsp;<a target="_blank"  href={"http://maps.google.com/?q=" + e.point.dataItem.address}> <Button icon="marker-pin" /> </a>
              </div>
          )} />
          <ChartSeries>
              <ChartSeriesItem
                  type="scatter"
                  data={activedata}
                  xField="acres"
                  yField="listPrice"
                  color="green"
                  markers={{
                    visible: activeChecked,
                    type: "square"
                  }}                
              />
              <ChartSeriesItem
                  type="scatter"
                  data={latestdata}
                  xField="acres"
                  yField="listPrice"
                  color="green"
                  markers={{
                    visible: activeChecked,
                    background: "blue",
                    size: 15,
                    type: "square"
                  }}                
              />
              <ChartSeriesItem
                  type="scatter"
                  data={pendingdata}
                  xField="acres"
                  yField="listPrice"
                  color= "red"
                  markers={{
                    visible: pendingChecked,
                    type: "cross"
                  }}                
              />
          </ChartSeries>
          <ChartXAxis>
              <ChartXAxisItem majorUnit={acresInterval} title={{ text: 'Area (Acres)' }} min={minAcres} max={maxAcres}/>
          </ChartXAxis>
          <ChartYAxis>
              <ChartYAxisItem majorUnit={priceInterval} title={{ text: 'ListPrice ($)' }} min={minListingPrice} max={maxListingPrice}/>
          </ChartYAxis>
      </Chart>
      </div>
    </TabStripTab>

    <TabStripTab title="Past Sales" contentClassName="panel-positions">
      &nbsp;&nbsp;|&nbsp;&nbsp; MaxPrice: <NumericTextBox value={maxListingPrice} width="150px" step={100000} onChange={changeMaxPrice}/> 
      &nbsp;&nbsp;|&nbsp;&nbsp; MaxAcres: <NumericTextBox value={maxAcres} width="150px" step={1} onChange={changeMaxAcres}/> 
      <Chart style={{ width: 1100 }}>
      <ChartTitle text={pastsalestitle} color="black" background="cyan" border={{color: "green", width: 2}}/>
          <ChartTooltip render={(e: any) => (
              <div>
                <a href={e.point.dataItem.listingId} target="_blank" >{e.point.dataItem.address}</a>
                <br></br> 
                {e.point.dataItem.acres} Acres - ${e.point.dataItem.listPrice}
                <br></br> 
                {e.point.dataItem.listingStatus}
              </div>
          )} />
          <ChartSeries>
              <ChartSeriesItem
                  type="scatter"
                  data={pastsalesdata}
                  xField="acres"
                  yField="listPrice"
                  color="red"
                  markers={{
                    visible: true,
                    type: "cross"
                  }}                
              />
          </ChartSeries>
          <ChartXAxis>
              <ChartXAxisItem majorUnit={acresInterval} title={{ text: 'Area (Acres)' }} min={minAcres} max={maxAcres}/>
          </ChartXAxis>
          <ChartYAxis>
              <ChartYAxisItem majorUnit={priceInterval} title={{ text: 'ListPrice ($)' }} min={minListingPrice} max={maxListingPrice}/>
          </ChartYAxis>

    </Chart>
    </TabStripTab>
  </TabStrip>
  )
}
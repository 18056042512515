import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { getLocations } from "../services/dataService";
import { Location } from "../data/models";

// const changeZip = React.useCallback(async () => {
//   setZip("78728")
// })

export default function LocationPanel(props: { handleZipClick: any; selectedPropertyType: string}) {
  const [llocations, setLlocations] = React.useState<Location[]>();

  React.useEffect(() => {
    console.log(props.selectedPropertyType);
    getLocations(props.selectedPropertyType).then((data: Location[]) => {
      setLlocations(data);
    });
  }, [props.selectedPropertyType]);
  
  return (
    <div>
    {llocations && llocations.map((item) => (
    <PanelBar key={item.city}>
      <PanelBarItem title={item.city} selected={true} key={item.city}>
      <div>
          {item && item.zips.map((zipitem) => (
              <span className="manager-info" key={zipitem.zip}>
                <Button onClick={() => props.handleZipClick(zipitem.zip)} look="outline">{zipitem.zip}</Button>
              </span>
          ))}
      </div>
    </PanelBarItem>
  </PanelBar>
  ))}
  </div>
  );
}

import { residentiallocations, landlocations } from "../data/locations";

import { Listing, Location, Listings, RentalHistory, ROIEstimate, CashFlow } from "../data/models";

var apihost = "https://api2.atxtrends.com";

function getRandomDelay() {
  // return 0;
  return 500 + (Math.random() * 2000);
  // return 9999999999;
}

var searchZip = "78626";

export function setZip(zip: string): Promise<Location[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log("Update Search Zip - Before:" + searchZip + " After:" + zip)
      searchZip = zip;
    }, getRandomDelay());
  })
}

export function getLocations(type: string): Promise<Location[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (type === "Residential") {
        resolve(residentiallocations);
      } else if (type === "Land") {
        resolve(landlocations);
      } else {
        resolve([])
      }
    }, getRandomDelay());
  })
}

// export function getLocations(): Promise<Location[]> {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       fetch('http://localhost:8080/locations')
//       .then(res => res.json())
//       .then((data) => {
//         resolve(data)
//       })
//       .catch(console.log);
//     }, getRandomDelay())
//   })
// }

export function getRentalHistory(zip: string): Promise<RentalHistory[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var rentalHistoryUrl = apihost+"/rental-history?zip="+zip;
      console.log("Get RentalHistory Url " + rentalHistoryUrl);
      fetch(rentalHistoryUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getListings(zip: string): Promise<Listings> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var rentalUrl = apihost+"/rental-listings?zip="+zip;
      console.log("Get RentalListings Url " + rentalUrl);
      fetch(rentalUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getPastRentalListings(zip: string): Promise<Listings> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var rentalUrl = apihost+"/past-rental-listings?zip="+zip;
      console.log("Get PastRentalListings Url " + rentalUrl);
      fetch(rentalUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getSalesListings(zip: string): Promise<Listings> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var salesListingUrl = apihost+"/sales-listings?zip="+zip;
      console.log("Get SalesListings Url " + salesListingUrl);
      fetch(salesListingUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getPastSalesListings(zip: string): Promise<Listings> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var pastSalesListingUrl = apihost+"/past-sales-listings?zip="+zip;
      console.log("Get PastSalesListings Url " + pastSalesListingUrl);
      fetch(pastSalesListingUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getLandListings(zip: string): Promise<Listings> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var landListingUrl = apihost+"/land-listings?zip="+zip;
      console.log("Get LandListings Url " + landListingUrl);
      fetch(landListingUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getPastLandListings(zip: string): Promise<Listings> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var pastLandListingUrl = apihost+"/past-land-listings?zip="+zip;
      console.log("Get PastLandListings Url " + pastLandListingUrl);
      fetch(pastLandListingUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getROIEstimate(listingId: string): Promise<ROIEstimate> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var roiEstimateUrl = apihost+"/roi-estimate?listingId="+listingId;
      console.log("Get roiEstimateUrl " + roiEstimateUrl);
      fetch(roiEstimateUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getCashFlow(cashFlow: CashFlow): Promise<CashFlow> {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json"},
    body: JSON.stringify(cashFlow)
  }
  return new Promise((resolve) => {
    setTimeout(() => {
      var cashFlowUrl = apihost+"/cashflow";
      console.log("Get CashFlow " + cashFlowUrl);
      fetch(cashFlowUrl, requestOptions)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}

export function getSoldListings(zip: string): Promise<Listing[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      var rentalUrl = apihost+"/sold-listings?zip="+zip;
      console.log("Get SoldListings Url " + rentalUrl);
      fetch(rentalUrl)
      .then(res => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch(console.log);
    }, getRandomDelay())
  })
}
export const residentiallocations = [
  { "city": "Latest", "zips": [ {zip: 70000} ] },  
  { "city": "Austin", "zips": [ {zip: 78717} , {zip: 78759}, {zip: 78744} ] },
  { "city": "CedarPark", "zips": [ {zip: 78613} ] },
  { "city": "Hutto", "zips": [ {zip: 78634} ] },
  { "city": "Georgetown", "zips": [ {zip: 78626} , {zip: 78628}, {zip: 78633} ] },
  { "city": "Leander", "zips": [ {zip: 78641} ] },
  { "city": "Kyle", "zips": [ {zip: 78640} ] },
  { "city": "Buda", "zips": [ {zip: 78610} ] },
  { "city": "Temple", "zips": [ {zip: 76502} ] },
  { "city": "SanMarcos", "zips": [ {zip: 78666} ] },
  { "city": "Roundrock", "zips": [ {zip: 78665}, {zip: 78681} ] },
  { "city": "Libertyhill", "zips": [ {zip: 78642} ] },
  { "city": "Manor", "zips": [ {zip: 78653} ] },
  { "city": "Elgin", "zips": [ {zip: 78621} ] },
  { "city": "Bastrop", "zips": [ {zip: 78602} ] },
  { "city": "Belton", "zips": [ {zip: 76513}]},
  { "city": "Jarrell", "zips": [ {zip: 76537}]}
];

export const landlocations = [
  { "city": "Jarrell", "zips": [ {zip: 76537}]},
  { "city": "Spicewood", "zips": [ {zip: 78669}]},
  { "city": "Salado", "zips": [ {zip: 76571}]},
  { "city": "LibertyHill", "zips": [ {zip: 78642}]},
  { "city": "Troy", "zips": [ {zip: 76579}]},
  { "city": "Temple", "zips": [ {zip: 76501}, {zip: 76502}, {zip: 76504}]},
  { "city": "Leander", "zips": [ {zip: 78641}, {zip: 78645}]},
  { "city": "Kyle", "zips": [ {zip: 78640} ] },
  { "city": "Buda", "zips": [ {zip: 78610} ] },
  { "city": "SanMarcos", "zips": [ {zip: 78666} ] },  
  { "city": "Bastrop", "zips": [ {zip: 78602} ] },
  { "city": "Manor", "zips": [ {zip: 78653}]},
  { "city": "Elgin", "zips": [ {zip: 78621}]},
  { "city": "Rockdale", "zips": [ {zip: 76567}]}
]
import React from "react";
import "hammerjs";

import DrawerContainer from "./layout/DrawerContainer";
import Dashboard from "./Dashboard";

import "./App.scss";

function App() {

  const [selectedPropertyType, setSelectedPropertyType] = React.useState("Residential"); 

  return (
      <DrawerContainer setPropertyType={setSelectedPropertyType}>
        <div className="page-container">
          <Dashboard selectedPropertyType={selectedPropertyType}/>
        </div>
      </DrawerContainer>
  );
}

export default App;

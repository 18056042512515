import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Chart,
  ChartTitle,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip
} from "@progress/kendo-react-charts";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { getSalesListings, getPastSalesListings, getROIEstimate, getCashFlow } from "../services/dataService";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Listings, ROIEstimate, CashFlow } from "../data/models";
import { Listing } from "../data/models";

export default function ListingsPanel(props: { selectedZip: string; }) {
  const [activedata, setSalesListings] = React.useState<Listing[]>([]);
  const [latestdata, setLatestListings] = React.useState<Listing[]>([]);  
  const [newconstructiondata, setNewConstructions] = React.useState<Listing[]>([]);
  const [pendingdata, setPendingData] = React.useState<Listing[]>([]);
  const [pastsalesdata, setPastSalesData] = React.useState<Listing[]>([]);
  const [selectedTab, setSelectedTab] = React.useState(0);  
  const [title, setTitle] = React.useState("Sqft vs ListPrice (" + props.selectedZip + ")");
  const [pastsalestitle, setPastSalesTitle] = React.useState("Sqft vs ListPrice (" + props.selectedZip + ")");
  const [activeChecked, setActiveChecked] = React.useState(true);
  const [newChecked, setNewChecked] = React.useState(true);
  const [pendingChecked, setPendingChecked] = React.useState(true);
  const [activeSalesLoaded, setActiveSalesLoaded] = React.useState(false);  
  const [pastSalesLoaded, setPastSalesLoaded] = React.useState(false);  
  const [roiEstimateData, setROIEstimateData] = React.useState<ROIEstimate>();
  const [minListingPrice, setMinListingPrice] = React.useState<number>(150000);
  const [maxListingPrice, setMaxListingPrice] = React.useState<number>(350000);
  const [minSqft, setMinSqft] = React.useState<number>(1000);
  const [maxSqft, setMaxSqft] = React.useState<number>(3000);  
  const [sqftInterval, setSqftInterval] = React.useState<number>(100);
  const [cashFlowData, setCashFlowData] = React.useState<CashFlow>(({ "listPrice": 300000, 
                                                                      "downPaymentPercent": 25,
                                                                      "mortgageInterest": 3.5,
                                                                      "annualPropertyTax": 6000,
                                                                      "annualHOA": 600,
                                                                      "annualHomeInsurance": 800,
                                                                      "annualPropertyManagement": 850,
                                                                      "otherCosts": 1000,
                                                                      "vacancyPercent": 5,
                                                                      "minRent": 1600,
                                                                      "minCashFlow": 0,
                                                                      "maxRent": 1900,
                                                                      "maxCashFlow": 0,
                                                                      "avgRent": 1800,
                                                                      "avgCashFlow": 0
                                                                    }));

  const [purchasePrice, setPurchasePrice] = React.useState<number>(300000);
  const [downPaymentPercent, setDownPaymentPercent] = React.useState<number>(25);
  const [mortgageInterest, setMortgageInterest] = React.useState<number>(3.5);
  const [annualPropertyTax, setAnnualPropertyTax] = React.useState<number>(6000);
  const [annualHOA, setAnnualHOA] = React.useState<number>(600);
  const [annualHomeInsurance, setAnnualHomeInsurance] = React.useState<number>(800);
  const [annualPropertyManagement, setAnnualPropertyManagement] = React.useState<number>(850);
  const [otherCosts, setOtherCosts] = React.useState<number>(1000);
  const [vacancyPercent, setVacancyPercent] = React.useState<number>(5);
  const [avgRent, setAverageRent] = React.useState<number>(1800);

  React.useEffect(() => {
    console.log("SalesListings panel rendered - " + props.selectedZip);
    setSelectedTab(0);
    setNewConstructions([]);
    setSalesListings([]);
    setLatestListings([]);
    setPendingData([]);
    setPastSalesData([]);
    setPastSalesLoaded(false);
    setActiveSalesLoaded(false);

    if (selectedTab === 0) {
      loadActiveSales();
    } else if (selectedTab === 1) {
      loadPastSales();
    }

  }, [props.selectedZip]);

  function loadActiveSales() {
    getSalesListings(props.selectedZip).then((results: Listings) => {
      if (props.selectedZip === "70000") {
        setTitle(" Recent Listings | Total:" + results.totalListings);
      } else {
        setTitle(results.city + " (" + results.zip + ") | TotalListings:" + results.totalListings);
      }
      setNewConstructions(results.newConstructiontListings);
      setSalesListings(results.activeListings);
      setPendingData(results.underContractListings);
      setLatestListings(results.latestListings);
      console.log("Loaded SalesListings");
    });
    setActiveSalesLoaded(true);
  }

  function loadPastSales() {
    getPastSalesListings(props.selectedZip).then((results: Listings) => {
      setPastSalesData(results.marketListings);
      if (props.selectedZip === "70000") {
        setPastSalesTitle(" Recent Listings | Total:" + results.totalListings);
      } else {
        setPastSalesTitle(results.city + " (" + results.zip + ") | TotalListings:" + results.totalListings);
      }
    });
    setPastSalesLoaded(true);
  }

  function loadROIEstimate(listingId: string) {
    setSelectedTab(2);
    getROIEstimate(listingId).then((results: ROIEstimate) => {
      setROIEstimateData(results);
      setCashFlowData(results.cashFlow);
      setPurchasePrice(results.cashFlow.listPrice);
      setDownPaymentPercent(results.cashFlow.downPaymentPercent);
      setMortgageInterest(results.cashFlow.mortgageInterest);
      setAnnualPropertyTax(results.cashFlow.annualPropertyTax);
      setAnnualHOA(results.cashFlow.annualHOA);
      setAnnualHomeInsurance(results.cashFlow.annualHomeInsurance);
      setAnnualPropertyManagement(results.cashFlow.annualPropertyManagement);
      setOtherCosts(results.cashFlow.otherCosts);
      setVacancyPercent(results.cashFlow.vacancyPercent);
      setAverageRent(results.cashFlow.avgRent);
    });
  }

  const handleSelect = (e: any) => {
    setSelectedTab(e.selected);
    if (e.selected === 0 && activeSalesLoaded === false) {
      loadActiveSales();
    } else if (e.selected === 1 && pastSalesLoaded === false) {
      loadPastSales();
    }
  }  

  const changeMaxPrice = (e: any) => {
    setMaxListingPrice(e.value);
  }

  const changeMaxSqft = (e: any) => {
    setMaxSqft(e.value);
    if(e.value > 3000) {
      setSqftInterval(200);
    } else {
      setSqftInterval(100);
    }
  }

  const updateCashFlow = (e: any) => {
    console.log(" Update CashFlow: PurchasePrice " + purchasePrice);
    cashFlowData.listPrice = purchasePrice;
    cashFlowData.downPaymentPercent = downPaymentPercent;
    cashFlowData.mortgageInterest = mortgageInterest;
    cashFlowData.annualPropertyTax = annualPropertyTax;
    cashFlowData.annualHOA = annualHOA;
    cashFlowData.annualHomeInsurance = annualHomeInsurance;
    cashFlowData.annualPropertyManagement = annualPropertyManagement;
    cashFlowData.otherCosts = otherCosts;
    cashFlowData.vacancyPercent = vacancyPercent;
    cashFlowData.avgRent = avgRent;
    getCashFlow(cashFlowData).then((results: CashFlow) => {
      setCashFlowData(results);
    });
  }

  const changeValue = (value: number, field: string) => {
    console.log("Value:" + value + " Field:" + field);
    // if (field === "purchaseprice")
    //   cashFlowData.listPrice = value;
    // else if (field === "downpayment")
    //   cashFlowData.downPaymentPercent = value;
    // else if (field === "Mortgage Interest")
    //   cashFlowData.mortgageInterest = value;
    // else if (field === "Property Tax Rate")
    //   cashFlowData.propertyTaxRate = value;
    // else if (field === "Annual HOA")
    //   cashFlowData.annualHOA = value;
    // else if (field === "Home Insurance")
    //   cashFlowData.annualHomeInsurance = value;
    // else if (field === "Property Management")
    //   cashFlowData.annualPropertyManagement = value;
    // else if (field === "Other Costs")
    //   cashFlowData.otherCosts = value;
    // else if (field === "Vacancy Percent")
    //   cashFlowData.vacancyPercent = value;
      console.log("ListPrice" + cashFlowData.listPrice);
  }

  return (
  <TabStrip selected={selectedTab} onSelect={handleSelect}>
    <TabStripTab title="Active Sales" contentClassName="panel-positions">
      <div>
        <span>
      <Checkbox defaultChecked={activeChecked} label={'Active'} onChange= {e=>setActiveChecked(!activeChecked)}/>
      &nbsp;&nbsp;|&nbsp;&nbsp; <Checkbox defaultChecked={newChecked} label={'New'} onChange= {e=>setNewChecked(!newChecked)}/>
      &nbsp;&nbsp;|&nbsp;&nbsp; <Checkbox defaultChecked={pendingChecked} label={'Contract'} onChange= {e=>setPendingChecked(!pendingChecked)}/>
      &nbsp;&nbsp;|&nbsp;&nbsp; MaxPrice: <NumericTextBox value={maxListingPrice} width="150px" step={100000} onChange={changeMaxPrice}/> 
      &nbsp;&nbsp;|&nbsp;&nbsp; MaxSqft: <NumericTextBox value={maxSqft} width="150px" step={200} onChange={changeMaxSqft}/> 
      </span>
      <Chart style={{ width: 1100 }}>
          <ChartTitle text={title} color="black" background="cyan" border={{color: "green", width: 2}}/>
          <ChartTooltip render={(e: any) => (
              <div>
                <a href={e.point.dataItem.listingId} target="_blank" >{e.point.dataItem.address}</a>
                <br></br> 
                {e.point.dataItem.sqft} Sqft - ${e.point.dataItem.listPrice}
                <br></br> 
                {e.point.dataItem.daysOnMarket} DOM &nbsp; {e.point.dataItem.priceCut}
                <br></br> 
                {e.point.dataItem.listingStatus}
                &nbsp;
                <Button look="bare" onClick={()=>loadROIEstimate(e.point.dataItem.listingId)}><b>ROI</b></Button>
              </div>
          )} />
          <ChartSeries>
              <ChartSeriesItem
                  type="scatter"
                  data={activedata}
                  xField="sqft"
                  yField="listPrice"
                  color="green"
                  markers={{
                    visible: activeChecked,
                    type: "square"
                  }}                
              />
              <ChartSeriesItem
                  type="scatter"
                  data={latestdata}
                  xField="sqft"
                  yField="listPrice"
                  color="green"
                  markers={{
                    visible: activeChecked,
                    background: "blue",
                    size: 15,
                    type: "square"
                  }}                
              />
              <ChartSeriesItem
                  type="scatter"
                  data={newconstructiondata}
                  xField="sqft"
                  yField="listPrice"
                  color= "blue"
                  markers={{
                    visible: newChecked,
                    type: "triangle"
                  }}                
              />
              <ChartSeriesItem
                  type="scatter"
                  data={pendingdata}
                  xField="sqft"
                  yField="listPrice"
                  color= "red"
                  markers={{
                    visible: pendingChecked,
                    type: "cross"
                  }}                
              />
          </ChartSeries>
          <ChartXAxis>
              <ChartXAxisItem majorUnit={sqftInterval} title={{ text: 'Area (Sqft)' }} min={minSqft} max={maxSqft}/>
          </ChartXAxis>
          <ChartYAxis>
              <ChartYAxisItem title={{ text: 'ListPrice ($)' }} min={minListingPrice} max={maxListingPrice} type="numeric"/>
          </ChartYAxis>
      </Chart>
      </div>
    </TabStripTab>

    <TabStripTab title="Past Sales" contentClassName="panel-positions">
      <Chart style={{ width: 1100 }}>
      <ChartTitle text={pastsalestitle} color="black" background="cyan" border={{color: "green", width: 2}}/>
          <ChartTooltip render={(e: any) => (
              <div>
                <a href={e.point.dataItem.listingId} target="_blank" >{e.point.dataItem.address}</a>
                <br></br> 
                {e.point.dataItem.sqft} Sqft - ${e.point.dataItem.listPrice}
                <br></br> 
                {e.point.dataItem.listingStatus}
              </div>
          )} />
          <ChartSeries>
              <ChartSeriesItem
                  type="scatter"
                  data={pastsalesdata}
                  xField="sqft"
                  yField="listPrice"
                  color="red"
                  markers={{
                    visible: true,
                    type: "cross"
                  }}                
              />
          </ChartSeries>
          <ChartXAxis>
              <ChartXAxisItem majorUnit={sqftInterval} title={{ text: 'Area (Sqft)' }} min={minSqft} max={maxSqft}/>
          </ChartXAxis>
          <ChartYAxis>
              <ChartYAxisItem title={{ text: 'ListPrice ($)' }} min={minListingPrice} max={maxListingPrice} type="numeric"/>
          </ChartYAxis>

    </Chart>
    </TabStripTab>

    <TabStripTab title="ROI Estimate" contentClassName="panel-positions">
      <div>
        <b>Property:</b>
          <a href={roiEstimateData?.property.listingId} target="_blank" style={{color: "blue"}}>
          {roiEstimateData?.property.address}
          </a>
          <a target="_blank"  href={"http://maps.google.com/?q=" + roiEstimateData?.property.address}> <Button icon="marker-pin" /> </a>
        <br/><b>Sqft:</b> {roiEstimateData?.property.sqft} &nbsp; &nbsp; <b>List Price:</b> {roiEstimateData?.property.listPrice}
        <table>
          <tbody>
        <tr>
        <td>
        <Grid
          style={{ height: '300px' }}
          data={roiEstimateData?.rentalEstimates}
          sortable>
          <Column field="city" title="City" sortable={false} width="100px"/>
          <Column field="sqft" title="Sqft" width="100px"/>
          <Column field="listPrice" title="Avg Price" width="100px"/>
          <Column field="totalListings" title="Total Listings"  sortable={false} width="100px"/>
          <Column field="listingStatus" title="Status" sortable={false} width="100px"/>
        </Grid>
        </td>
        <td>          
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={purchasePrice} label="List Price" width="200px" onChange={(e) => setPurchasePrice((e.value != null ? e.value : 0))}/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={avgRent} label="Avg Rent" width="200px" onChange={(e) => setAverageRent((e.value != null ? e.value : 0))}/>
            &nbsp;&nbsp;&nbsp;&nbsp;<Button icon="reload" look="flat" onClick={updateCashFlow} /><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={downPaymentPercent} label="Down Payment Percent" width="200px" onChange={(e) => setDownPaymentPercent((e.value != null ? e.value : 0))}/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={mortgageInterest} label="Mortgage Interest" width="200px" onChange={(e) => setMortgageInterest((e.value != null ? e.value : 0))}/> <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={annualPropertyTax} label="Annual Property Tax" width="200px" onChange={(e) => setAnnualPropertyTax((e.value != null ? e.value : 0))}/> 
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={annualHOA} label="Annual HOA" width="200px" onChange={(e) => setAnnualHOA((e.value != null ? e.value : 0))}/> <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={annualHomeInsurance} label="Home Insurance" width="200px" onChange={(e) => setAnnualHomeInsurance((e.value != null ? e.value : 0))}/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={annualPropertyManagement} label="Property Management" width="200px" onChange={(e) => setAnnualPropertyManagement((e.value != null ? e.value : 0))}/> <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={otherCosts} label="Other Costs" width="200px" onChange={(e) => setOtherCosts((e.value != null ? e.value : 0))}/>
            &nbsp;&nbsp;&nbsp;&nbsp;<NumericTextBox value={vacancyPercent} label="Vacancy Percent" width="200px" onChange={(e) => setVacancyPercent((e.value != null ? e.value : 0))}/> <br/>            
            <b>Min Rent: </b>{cashFlowData?.minRent} &nbsp; <b>CashFlow: </b> {cashFlowData?.minCashFlow}<br/>
            <b>Avg Rent: </b> {cashFlowData?.avgRent}&nbsp; <b>CashFlow: </b> {cashFlowData?.avgCashFlow}<br/>
            <b>Max Rent: </b>{cashFlowData?.maxRent} &nbsp; <b>CashFlow: </b> {cashFlowData?.maxCashFlow}
        </td>
        </tr>
        </tbody>
        </table>
      </div>
    </TabStripTab>
  </TabStrip>
  )
}
//import React from "react";
import React, { useContext } from 'react'

import LocationPanel from "./panels/LocationPanel";
import ListingsPanel from "./panels/ListingsPanel";
import SalesListingsPanel from "./panels/SalesListingsPanel";
import LandListingsPanel from "./panels/LandListingsPanel";

export default function Dashboard(props: { selectedPropertyType: string; }) {

  const [selectedZip, setSelectedZip] = React.useState("78642"); 

  const handleZipClick = (newValue: React.SetStateAction<string>) => {
    console.log("HandleZipClick - " + newValue);
    setSelectedZip(newValue); 
  }

  const isResidential = () => {
    return props.selectedPropertyType === 'Residential';
  }

  const isLand = () => {
    return props.selectedPropertyType === 'Land';
  }

  const isNotRecent = () => {
    return selectedZip != "70000";
  }

  return (
    <div className="panels">
      <div className="panel-info">
        <LocationPanel handleZipClick={handleZipClick} selectedPropertyType={props.selectedPropertyType}/>
      </div>
      { isResidential() && 
        <div>
        <div className="panel-sales">
          <SalesListingsPanel selectedZip={selectedZip}/>
        </div>       

        { isNotRecent() &&
        <div className="panel-positions">
          <ListingsPanel selectedZip={selectedZip}/>
        </div>
        }

        </div>
      }
      {
        isLand() && 
        <div className="panel-sales">
          <LandListingsPanel selectedZip={selectedZip}/>
        </div>                
      }
    </div>
  )
}